export default [
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/pages/billings/Invoices.vue'),
    meta: {
      pageTitle: 'Invoices',
      breadcrumb: [
        {
          text: 'Invoices',
          active: true,
        }
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/invoice/:id/transactions-history/:invoiceNumber',
    name: 'invoice-transactions-history',
    component: () => import('@/views/pages/billings/InvoiceTransactionHistory.vue'),
    props: true,
    meta: {
      pageTitle: 'Invoices',
      breadcrumb: [
        {
          text: 'Invoices',
          to: "/invoices",
        },
        {
          text: 'Invoice Transactions History',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/transactions-history',
    name: 'transactions-history',
    component: () => import('@/views/pages/billings/TransactionHistory.vue'),
    meta: {
      pageTitle: 'Transactions History',
      breadcrumb: [
        {
          text: 'Billing',
          active: true,
        },
        {
          text: 'Transactions History',
          active: true,
        }
      ],
      requiresAuth: true,
    },
  },
];