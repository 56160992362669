export default [
  {
    path: '/sender-id',
    name: 'sender-id',
    component: () => import('@/views/pages/reports/SenderId.vue'),
    meta: {
      pageTitle: 'Sender Id',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
        {
          text: 'Sender Id',
          active: true,
        }
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/sms/sms-history',
    name: 'sms-history',
    component: () => import('@/views/pages/reports/Outbox.vue'),
    meta: {
      pageTitle: 'SMS History',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
        {
          text: 'SMS History',
          active: true,
        }
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/sms/scheduled-sms',
    name: 'scheduled-sms',
    component: () => import('@/views/pages/reports/ScheduledMessages.vue'),
    meta: {
      pageTitle: 'Scheduled SMS',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
        {
          text: 'Scheduled SMS',
          active: true,
        }
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/voice/voice-history',
    name: 'voice-history',
    component: () => import('@/views/pages/reports/OutboxVoices.vue'),
    meta: {
      pageTitle: 'Voice History',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
        {
          text: 'Voice History',
          active: true,
        }
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/voice/scheduled-voices',
    name: 'scheduled-voices',
    component: () => import('@/views/pages/reports/ScheduledVoices.vue'),
    meta: {
      pageTitle: 'Scheduled Voices',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
        {
          text: 'Scheduled Voices',
          active: true,
        }
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/pages/reports/Contacts.vue'),
    meta: {
      pageTitle: 'Contacts',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
        {
          text: 'Contacts',
          active: true,
        }
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/invalid-contacts',
    name: 'invalid-contacts',
    component: () => import('@/views/pages/reports/InvalidContacts.vue'),
    meta: {
      pageTitle: 'Invalid Contacts',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
        {
          text: 'Invalid Contacts',
          active: true,
        }
      ],
      requiresAuth: true,
    },
  },
]