export default [
  {
    path: '/admins',
    name: 'admins',
    component: () => import('@/views/pages/admins/Admin.vue'),
    meta: {
      pageTitle: 'Admins',
      breadcrumb: [
        {
          text: 'Admins',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
]