export default [
  {
    path: '/countries',
    name: 'countries',
    component: () => import('@/views/pages/countries/Countries.vue'),
    meta: {
      pageTitle: 'Countries',
      breadcrumb: [
        {
          text: 'Countries',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
]