import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import auth from './routes/auth'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'

import dashboard from './routes/dashboard'
import notFound from './routes/not-found'
import billing from './routes/billing'
import report from './routes/report'
import user from './routes/client'
import client from './routes/user'
import country from './routes/country'
import admin from './routes/admin'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...auth,
    ...dashboard,
    ...report,
    ...billing,
    ...user,
    ...client,
    ...country,
    ...admin,
    ...notFound,
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if(to.meta.requiresAuth && !isLoggedIn){
    return next({ name: 'login' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser("dummy-user"))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router