import jwtDefaultConfig from './jwtDefaultConfig'
import router from '../../router'
export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }


  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

     // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        const { response } = error

        if (response && response.status === 401 && response.data.error[0] == "Invalid User, Redirecting to login page.") {
          localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
          localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);
          // Remove userData from localStorage
          localStorage.removeItem('userData');
          localStorage.removeItem("smsUnits");
          router.push({ name: 'login' });
        }
        return Promise.reject(error);
      },
    )
  }

 


  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  login(...args) {
   return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }
}
