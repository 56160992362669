import Vue from 'vue'
import { $themeConfig } from '@themeConfig'
// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}

  baseURL: $themeConfig.app.appUrl + '/v1/api',

})

Vue.prototype.$http = axiosIns

export default axiosIns
