export default [
  {
    path: '/clients',
    name: 'clients',
    component: () => import('@/views/pages/clients/Client.vue'),
    meta: {
      pageTitle: 'Clients',
      breadcrumb: [
        {
          text: 'Clients',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/clients/manage/:clientId',
    name: 'client-manage',
    component: () => import('@/views/pages/clients/ClientManage.vue'),
    props: true,
    meta: {
      pageTitle: 'Client Manage',
      breadcrumb: [
        {
          text: 'Clients',
          to: "/clients",
        },
        {
          text: 'Client Manage',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
]