export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/pages/users/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        }
      ],
      requiresAuth: true,
    },
  },
]
    